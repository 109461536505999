<template>
    <div class="institution-collections">
        <div class="col-12">
            <h4 class="title">Merklisten</h4>
        </div>
        <div class="scroll-container">
            <card-collection
            v-for="collection in collections"
            :key="collection.id"
            :collection="collection"
            :institution-prop="institution"
            :isSmall=true
            :width="'200px'"
            :margin="'10px'"/>
        </div>
    </div>
</template>
<script>
import CardCollection from '@/components/cards/CardCollection.vue';

export default {
    name: "institutionCollections",
    components: {
        CardCollection,
    },
    props: {
        institution: {
            type: Object,
            required: true
        },
        dataSet: {
            type: Object,
            required: true
        }
    },
    emits: ["changePage"],
    data(){
        return{

        }
    },
    computed: {
        collections(){
            var collections = [];
            if(this.dataSet!=null && this.dataSet.data!=null){
                for(var i = 0; i < this.dataSet.data.length; i++){
                    if(this.dataSet.data[i].noOfContents>0){
                        collections.push(this.dataSet.data[i]);
                    }
                }
            }
            return collections;
        }
    },
    methods: {
        pageChanged(aPage){
            this.$emit("changePage", aPage);
        },
    },
}
</script>

<style lang="scss" scoped>

.institution-collections .scroll-container{

    @media (max-width: 992px) {
        flex-wrap: nowrap;
    }
}

.institution-collections .scroll-container .card-collections{

    @media (max-width: 992px) {
        width: 300px!important;
        min-width: 300px!important;
        margin-bottom: 20px!important;
        padding-bottom: 0px!important;
    }

}

.institution-collections .card-collections .card-img{
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;

}

.institution-collections .card-collections{
    min-width: auto;
}

.institution-collections{
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.institution-collections .title{
    padding-left: calc(var(--bs-gutter-x) / 2);
}

</style>
